/* link that covers the whole tile */
.productLink {
    line-height: 1.2;
}
.productLink:not(:hover) {
    text-decoration: none;
}
.productLink:after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    outline-offset: .3rem;
}


.imageCard {
    --duration-medium: .3s;
    --ease-out-slow: cubic-bezier(0, 0, .3, 1);
}
.imageCard:hover .imageGallery {
    transform: translateY(-.3em);
}
.imageGallery {
    transition: transform .3s cubic-bezier(0, 0, .3, 1);
}

/* styles for Product Card pseudo gallery */
.imageGalleryHoverable {
    position: relative;
}
.imageGalleryHoverable .image:last-of-type {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
}
.imageCard:hover .image:last-of-type {
    opacity: 1;
    border-radius: .5em;
}
.image {
    border-radius: .5em;
    overflow: hidden;
    transition: all var(--duration-medium) var(--ease-out-slow);
}
